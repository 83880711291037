import React, { useCallback } from "react";
import { Button } from "@material-ui/core";
import { plaidPayroll } from "../../config/plaid";
import { getAccessToken } from "../../util/functions";
import { usePlaidLink } from "react-plaid-link";

const PlaidLinkPayroll = ({ callback, handoff }) => {
  const onSuccess = useCallback(
    (token, metadata) => {
      async function processToken(payload) {
        try {
          const data = await getAccessToken(payload);
          callback(data);
          handoff();
        } catch (error) {
          console.error(error);
        }
      }
      // send token to server
      const payload = {};
      payload.public_token = token;
      payload.metadata = metadata;
      processToken(payload);
    },
    [callback, handoff]
  );
  const onEvent = useCallback(
    (eventName, metadata) => {
      if (eventName === "HANDOFF") {
        if (metadata.error_code === null) {
          handoff();
        }
      }
    },
    [handoff]
  );

  const config = { ...plaidPayroll };
  config.onSuccess = onSuccess;
  config.onEvent = onEvent;
  // config.onExit = onExit;
  const { open, ready, error } = usePlaidLink(config);

  return (
    <Button
      size="large"
      variant="contained"
      fullWidth
      color="primary"
      onClick={() => open()}
      disabled={!ready}
    >
      Connect my payroll system
    </Button>
  );
};

export default PlaidLinkPayroll;
