import React from "react";
import { Box, Container, Typography, Paper } from "@material-ui/core";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

import copy from "./copy.json";

const VerifyLoan = ({ next, back, firstName, entityName, PPPLoanAmount }) => {
  return (
    <Layout centered>
      <Box>
        <Container maxWidth="sm">
          <Box>
            <Box mb={20}>
              <Typography variant="h4">{copy.heading}</Typography>
            </Box>
            <Box>
              <Typography variant="body1">{copy.subheading}</Typography>
            </Box>
            <Box mt={0}>
              <Typography variant="body1">Thank you {firstName},</Typography>

              <Box mt={20}>
                <Typography variant="body1">
                  Your SBA PPP loan application for {entityName} is ready to
                  submit.
                </Typography>
              </Box>

              <Box mt={30}>
                <Typography variant="h6">Your total loan request</Typography>
              </Box>

              <Box mt={5}>
                <Paper>
                  <Box px={15} py={20}>
                    <Typography
                      variant="h6"
                      color="primary"
                      style={{ fontWeight: 700 }}
                    >
                      {(PPPLoanAmount &&
                        PPPLoanAmount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD"
                        })) ||
                        "$0"}
                    </Typography>
                  </Box>
                </Paper>
              </Box>

              <Box mt={30}>
                <Typography variant="body1">
                  If this looks correct, you can move on to the next screen,
                  where you will attest that you have answered all questions
                  honestly and accurately to the best of your ability.
                </Typography>
              </Box>
              <Box mt={30}>
                <Typography variant="body1">Well done,</Typography>
              </Box>
              <Box mt={20}>
                <Typography variant="body1">The Sortis SBA Team</Typography>
              </Box>
            </Box>
          </Box>
          <FormNavButtons>
            <FormNavButton back={back}></FormNavButton>
            <FormNavButton next={next}></FormNavButton>
          </FormNavButtons>
        </Container>
      </Box>
    </Layout>
  );
};

export default VerifyLoan;
