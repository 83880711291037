import React from "react";
import { Box, LinearProgress, Container } from "@material-ui/core";
// import { stageNames } from "../../util/helpers";

const FormProgress = ({ currentStep, totalSteps }) => {
  const completed = Math.round((currentStep / totalSteps) * 100);
  const isLastStep = currentStep === totalSteps;
  return (
    <Box className="FormProgress">
      {currentStep > 1 && !isLastStep && (
        <Container maxWidth="sm">
          <LinearProgress
            variant="determinate"
            value={completed}
            color="primary"
          />
        </Container>
      )}
    </Box>
  );
};

export default FormProgress;
