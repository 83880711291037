import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  FormControlLabel,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  Grid,
  Box
} from "@material-ui/core";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

import copy from "./copy.json";

const BusinessInfo = ({ back, next, setInputs: setFormInputs, nextStep }) => {
  const [inputs, setInputs] = useState({});

  const handleInputChange = e => {
    e.persist();
    if (e.target.name === "NAICSCode" && e.target.value !== "") {
      if (/^[0-9]*$/.test(e.target.value) === false) return;
    }

    if (e.target.name === "tin" && e.target.value !== "") {
      if (/^[0-9]*$/.test(e.target.value) === false) return;
      if (e.target.value && e.target.value.length > 10) return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectChange = e => {
    e.persist();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxChange = (e, i) => {
    e.persist();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.checked
    });
  };

  const goForward = () => {
    setFormInputs(inputs);
    nextStep();
  };

  const isProgressDisabled =
    !inputs.entityType ||
    !inputs.entityName ||
    !inputs.dba ||
    !inputs.tin ||
    !inputs.businessFoundedDate ||
    !inputs.NAICSCode;

  return (
    <Layout>
      <Container maxWidth="sm">
        <Box mb={20}>
          <Typography variant="h4">{copy.heading}</Typography>
        </Box>
        <Box mb={20}>
          <Typography variant="body1">{copy.subheading}</Typography>
        </Box>
        <Grid container spacing={2} style={{ maxWidth: "400px" }}>
          <Grid item xs={12}>
            <FormControl margin="normal" variant="outlined">
              <InputLabel id="entityTypeLabel">Business entity type</InputLabel>
              <Select
                labelId="entityTypeLabel"
                name="entityType"
                id="entityType"
                value={inputs.entityType || ""}
                onChange={handleSelectChange}
                label="Business entity type"
              >
                <MenuItem value={"soleProprietor"}>Sole proprietor</MenuItem>
                <MenuItem value={"partnership"}>Partnership</MenuItem>
                <MenuItem value={"c-corp"}>C-Corp</MenuItem>
                <MenuItem value={"s-corp"}>S-Corp</MenuItem>
                <MenuItem value={"llc"}>LLC</MenuItem>
                <MenuItem value={"independentContractor"}>
                  Independent contractor
                </MenuItem>
                <MenuItem value={"eligibleSelfEmployedIndividual"}>
                  Eligible self-employed individual
                </MenuItem>
                <MenuItem value={"501(c)(3)"}>501(c)(3) nonprofit</MenuItem>
                <MenuItem value={"501(c)(19)"}>Veterans organization</MenuItem>
                <MenuItem value={"tribalBusiness"}>
                  Tribal business (sec. 31(b)(2)(C) of Small Business Act)
                </MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl margin="normal">
              <TextField
                fullWidth
                variant="outlined"
                id="entityName"
                name="entityName"
                required
                label="Business legal name"
                value={inputs.entityName || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl margin="normal">
              <TextField
                fullWidth
                variant="outlined"
                id="dba"
                name="dba"
                required
                label="DBA or Tradename"
                value={inputs.dba || ""}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl margin="normal">
              <TextField
                fullWidth
                variant="outlined"
                id="tin"
                name="tin"
                helperText="Must be 10 digits. If EIN, add 0 to the front. If SSN add 1 to
                the front."
                required
                label="Business TIN (EIN,SSN)"
                value={inputs.tin || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl margin="normal">
              <Box mb={15} mt={10}>
                <a
                  href="https://www.sba.gov/sites/default/files/2019-08/SBA%20Table%20of%20Size%20Standards_Effective%20Aug%2019%2C%202019.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1c529c" }}
                >
                  Not sure? Find your NAICS Code here
                </a>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                id="NAICSCode"
                name="NAICSCode"
                required
                label="NAICS Code"
                value={inputs.NAICSCode || ""}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl margin="normal">
              <TextField
                fullWidth
                variant="outlined"
                id="businessFoundedDate"
                name="businessFoundedDate"
                required
                label="Date your business was founded"
                value={inputs.businessFoundedDate || ""}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px"
                }}
                control={
                  <Checkbox
                    checked={inputs.isFranchise || false}
                    onChange={handleCheckboxChange}
                    name="isFranchise"
                    color="secondary"
                  />
                }
                label="Is your business a franchise?"
              />
              <Typography variant="body2">
                If you select yes, your business must be listed in the SBA
                Franchise Directory to qualify for this loan.
              </Typography>
            </FormControl>
            <FormControl>
              <FormControlLabel
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "30px"
                }}
                control={
                  <Checkbox
                    checked={inputs.isSeasonal || false}
                    onChange={handleCheckboxChange}
                    name="isSeasonal"
                    color="secondary"
                  />
                }
                label="Is your business seasonal?"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                style={{ display: "flex", alignItems: "center" }}
                control={
                  <Checkbox
                    checked={inputs.isNew || false}
                    onChange={handleCheckboxChange}
                    name="isNew"
                    color="secondary"
                  />
                }
                label="Is your business new?"
              />
            </FormControl>
          </Grid>
        </Grid>

        <FormNavButtons>
          <FormNavButton back={back}></FormNavButton>
          <FormNavButton
            next={next}
            disabled={isProgressDisabled}
            nextFunc={goForward}
          ></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default BusinessInfo;
