import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";
import { mapStatesToOptions } from "../../util/helpers";
import { cloneDeep } from "lodash";

const INITIAL_OWNER_OBJ = {
  ownerName: "",
  ownerTitle: "",
  ownerOwnershipPercent: 0,
  ownerTIN: "",
  ownerAddressLine1: "",
  ownerAddressLine2: "",
  ownerCity: "",
  ownerState: "",
  ownerZipcode: ""
};

const Owners = ({ back, next, setInputs: setFormInputs, nextStep }) => {
  const [inputs, setInputs] = useState([{ ...INITIAL_OWNER_OBJ }]);
  const [totalPercent, setTotalPercent] = useState(0);

  const addOwner = () => {
    setInputs([...inputs, { ...INITIAL_OWNER_OBJ }]);
  };

  const removeOwner = groupIndex => {
    if (inputs.length <= 1) return;
    const newInputs = inputs.filter((group, i) => i !== groupIndex);
    setInputs(newInputs);
    handleTotalPercentChange(newInputs);
  };

  const handleInputChange = (e, i, name) => {
    e.persist();
    if (name === "ownerZipcode" && e.target.value !== "") {
      if (e.target.value && e.target.value.length > 5) {
        return;
      }

      if (/^[0-9]*$/.test(e.target.value) === false) {
        return;
      }
    }

    if (name === "ownerTIN" && e.target.value !== "") {
      if (/^[0-9]*$/.test(e.target.value) === false) return;
      if (e.target.value && e.target.value.length > 10) return;
    }

    if (name === "ownerOwnershipPercent" && e.target !== "") {
      if (/^[0-9]*$/.test(e.target.value) === false) return;

      if (e.target.value > 100) return;
    }

    const newInputs = cloneDeep(inputs);
    const obj = newInputs[i];
    obj[name] = e.target.value;
    setInputs(newInputs);
    handleTotalPercentChange(newInputs);
  };

  const handleTotalPercentChange = inp => {
    const totalPercent = inp.reduce((acc, cur) => {
      const percent = parseInt(cur.ownerOwnershipPercent) || 0;
      return acc + percent;
    }, 0);
    setTotalPercent(totalPercent);
  };

  const goForward = () => {
    setFormInputs(inputs);
    nextStep();
  };

  const isProgressDisabled =
    (Array.isArray(inputs) &&
      !Object.keys(inputs[0]).every(input => {
        if (input === "ownerAddressLine2") return true;
        return !!inputs[0][input];
      })) ||
    totalPercent <= 0 ||
    totalPercent > 100;

  return (
    <Layout scrolling>
      <Container maxWidth="sm">
        <Typography variant="h4">Owner info</Typography>
        <Typography variant="body1">
          Any individual with at least 20% share in the business must be listed
          on the application. Add these owners using the link below.
        </Typography>

        <Grid container spacing={2} style={{ maxWidth: "400px" }}>
          {inputs.map((group, i) => {
            return (
              <Box my={30} key={"owner" + i}>
                <Box pb={10}>
                  <Typography variant="h6">Owner {i + 1}</Typography>
                </Box>
                <Box>
                  <Grid item xs={12}>
                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={`ownerName${i}`}
                        name={`ownerName${i}`}
                        required
                        label="Owner name"
                        value={inputs[i].ownerName || ""}
                        onChange={e => handleInputChange(e, i, "ownerName")}
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={`ownerTitle${i}`}
                        name={`ownerTitle${i}`}
                        required
                        label="Title"
                        value={inputs[i].ownerTitle || ""}
                        onChange={e => handleInputChange(e, i, "ownerTitle")}
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={`ownerOwnershipPercent${i}`}
                        name={`ownerOwnershipPercent${i}`}
                        required
                        type="number"
                        inputProps={{ min: "20", max: "100", step: "1" }}
                        label="Ownership %"
                        value={inputs[i].ownerOwnershipPercent || ""}
                        onChange={e =>
                          handleInputChange(e, i, "ownerOwnershipPercent")
                        }
                      />
                    </FormControl>

                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        helperText="Must be 10 digits. If EIN, add 0 to the front. If SSN add 1 to
                the front."
                        variant="outlined"
                        id={`ownerTIN${i}`}
                        name={`ownerTIN${i}`}
                        required
                        label="TIN (EIN,SSN)"
                        value={inputs[i].ownerTIN || ""}
                        onChange={e => handleInputChange(e, i, "ownerTIN")}
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={`ownerAddressLine1${i}`}
                        name={`ownerAddressLine1${i}`}
                        required
                        label="Address line 1"
                        value={inputs[i].ownerAddressLine1 || ""}
                        onChange={e =>
                          handleInputChange(e, i, "ownerAddressLine1")
                        }
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={`ownerAddressLine2${i}`}
                        name={`ownerAddressLine2${i}`}
                        label="Address line 2"
                        value={inputs[i].ownerAddressLine2 || ""}
                        onChange={e =>
                          handleInputChange(e, i, "ownerAddressLine2")
                        }
                      />
                    </FormControl>
                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={`ownerCity${i}`}
                        name={`ownerCity${i}`}
                        required
                        label="City"
                        value={inputs[i].ownerCity || ""}
                        onChange={e => handleInputChange(e, i, "ownerCity")}
                      />
                    </FormControl>

                    <FormControl margin="normal" variant="outlined">
                      <InputLabel id={`ownerState${i}Label`}>State</InputLabel>
                      <Select
                        fullWidth
                        labelId={`ownerState${i}Label`}
                        name={`ownerState${i}`}
                        id={`ownerState${i}`}
                        value={inputs[i].ownerState || ""}
                        onChange={e => handleInputChange(e, i, "ownerState")}
                        label="State"
                      >
                        {mapStatesToOptions().map(opt => (
                          <MenuItem key={opt.value + i} value={opt.value}>
                            {opt.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl margin="normal">
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={`ownerZipcode${i}`}
                        name={`ownerZipcode${i}`}
                        required
                        label="5 digit zipcode"
                        value={inputs[i].ownerZipcode || ""}
                        onChange={e => handleInputChange(e, i, "ownerZipcode")}
                      />
                    </FormControl>
                  </Grid>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={() => removeOwner(i)}>REMOVE</Button>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Grid>
        <Box my={30} display="flex" justifyContent="center">
          <Button
            onClick={addOwner}
            size="large"
            color="secondary"
            startIcon={<Add />}
          >
            ADD ADDITIONAL OWNER
          </Button>
        </Box>
        <Box my={30}>
          <Typography variant="h6">
            Total ownership listed should not exceed 100%:{" "}
            <span style={{ color: totalPercent > 100 ? "red" : "green" }}>
              {totalPercent}%
            </span>
          </Typography>
        </Box>
        <FormNavButtons>
          <FormNavButton back={back}></FormNavButton>
          <FormNavButton
            disabled={isProgressDisabled}
            nextFunc={goForward}
          ></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default Owners;
