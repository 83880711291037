export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://us-central1-sortis-sba.cloudfunctions.net/sortis"
    : "";

export const stageNames = {
  "testing stage 1": 3,
  "testing stage 2": 5,
  "testing stage 3": 7,
  "testing stage 4": 9
};

export function packageFormData(state) {
  const {
    verifyTimeInOperation,
    verifyEconomicUncertainty,
    verifyUseOfFunds,
    verifyNoAdditionalPPPLoan,
    verifyQualifiedBusiness,
    verifyPrinciplePlaceOfBusinessUS,
    passedDisqual,
    entityType,
    entityName,
    dba,
    tin,
    businessAddressLine1,
    businessAddressLine2,
    businessAddressCity,
    businessAddressState,
    businessAddressZipcode,
    businessAddressZipcodeFourDigit,
    businessPrimaryTel,
    isSeasonal,
    isNew,
    owners,
    payrollAmount,
    prevLoanAmount,
    employeeCount,
    PPPLoanAmount,
    payrollLinked,
    usingPlaidPayroll,
    loanPurpose,
    firstName,
    lastName,
    email,
    zipcode,
    tel,
    NAICSCode,
    isFranchise,
    businessFoundedDate,
    signedName,
    signedDate,
    agreeFinalTerms
  } = state;
  const payload = {
    verifyEconomicUncertainty,
    verifyNoAdditionalPPPLoan,
    verifyPrinciplePlaceOfBusinessUS,
    verifyTimeInOperation,
    verifyUseOfFunds,
    verifyQualifiedBusiness,
    passedDisqual,
    entityType,
    entityName,
    dba,
    tin,
    businessAddressLine1,
    businessAddressLine2,
    businessAddressCity,
    businessAddressState,
    businessAddressZipcode,
    businessAddressZipcodeFourDigit,
    businessPrimaryTel,
    isSeasonal,
    isNew,
    owners,
    payrollAmount,
    prevLoanAmount,
    employeeCount,
    PPPLoanAmount,
    payrollLinked,
    usingPlaidPayroll,
    loanPurpose,
    userFirstName: firstName,
    userLastName: lastName,
    userEmail: email,
    userZipcode: zipcode,
    userTel: tel,
    NAICSCode,
    isFranchise,
    businessFoundedDate,
    signedName,
    signedDate,
    agreeFinalTerms
  };
  return payload;
}

export function mapStatesToOptions() {
  return Object.keys(USStates).map(key => ({
    value: key,
    name: USStates[key]
  }));
}

export const USStates = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};
