import React, { PureComponent } from "react";
import {
  Container,
  Typography,
  Button,
  LinearProgress,
  Box,
  Paper
} from "@material-ui/core";
import PlaidLinkPayroll from "../../components/PlaidLinkPayroll";
import Layout from "../../components/Layout";

import copy from "./copy.json";

import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

class VerifyPayroll extends PureComponent {
  state = {
    displayVerification: false,
    isWorking: false
  };
  componentDidUpdate(prevProps) {
    const { isLoading } = this.props;
    const { isLoading: prevIsLoading } = prevProps;
    if (!isLoading && prevIsLoading) {
      this.setState({
        displayVerification: true,
        isWorking: false
      });
    }
  }

  handleNotListProvider = () => {
    const { setInputs: setFormInputs, nextStep } = this.props;
    setFormInputs({ usingPlaidPayroll: false });
    nextStep();
  };

  displayWorking = () => {
    this.setState({
      isWorking: !this.state.isWorking
    });
  };

  render() {
    const {
      next,
      back,
      displayPlaid,
      callback,
      payrollLinked,
      usingPlaidPayroll,
      isLoading
      // payrollError
    } = this.props;

    const isProgressDisabled = payrollLinked
      ? false
      : usingPlaidPayroll
      ? true
      : false;

    const localLoading = isLoading || this.state.isWorking;
    return (
      <Layout centered>
        <Container maxWidth="sm">
          <Box>
            <Box mb={20}>
              <Typography variant="h4">{copy.heading}</Typography>
            </Box>
            <Box style={{ whiteSpace: "pre-wrap" }}>
              <Typography variant="body1">{copy.subheading}</Typography>
            </Box>
          </Box>
          <Box
            my={40}
            style={{
              display: localLoading || payrollLinked ? "none" : "block"
            }}
          >
            {displayPlaid && (
              <PlaidLinkPayroll
                callback={callback}
                handoff={this.displayWorking}
              />
            )}
          </Box>
          {localLoading && (
            <Paper elevation={3}>
              <Box
                mt={30}
                px={15}
                py={25}
                display="flex"
                flexDirection="column"
                width="300px"
              >
                <Box>
                  <LinearProgress color="primary" />
                </Box>
                <Box mt={15}>
                  <Typography variant="body2">
                    Connecting to your payroll system...
                  </Typography>
                </Box>
              </Box>
            </Paper>
          )}
          {this.state.displayVerification && (
            <Paper elevation={3}>
              <Box mt={30} px={15} py={25}>
                {payrollLinked ? (
                  <Typography style={{ color: "green" }} variant="body1">
                    Successfully connected to your payroll system. Please
                    proceed.
                  </Typography>
                ) : (
                  <Typography style={{ color: "red" }} variant="body1">
                    Sorry we couldn't connect to your payroll system. Please try
                    again.
                  </Typography>
                )}
              </Box>
            </Paper>
          )}
          {!isLoading && !payrollLinked && (
            <Box mt={120}>
              <Paper elevation={0}>
                <Box px={15} py={20}>
                  <Typography variant="body1">
                    If you don't use any of the payroll providers listed above,
                    you will need to calculate your payroll amount manually. We
                    approve the use of AICPA's calculation methodology to do
                    this.{" "}
                    <a
                      href="https://www.aicpa.org/interestareas/privatecompaniespracticesection/qualityservicesdelivery/sba-paycheck-protection-program-resources-for-cpas.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#1c529c" }}
                    >
                      Click here for details
                    </a>
                    .
                  </Typography>

                  <Box mt={25}>
                    <Button
                      style={{ color: "red" }}
                      onClick={this.handleNotListProvider}
                    >
                      TO PROCEED I VERIFY WE USE A PAYROLL PROVIDER NOT LISTED
                      ABOVE AND UNDERSTAND I MUST SUBMIT A MANUAL CALCULATION
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}

          <FormNavButtons>
            <FormNavButton back={back}></FormNavButton>
            <FormNavButton
              next={next}
              disabled={isProgressDisabled}
            ></FormNavButton>
          </FormNavButtons>
        </Container>
      </Layout>
    );
  }
}

export default VerifyPayroll;
