export const plaidIdentity = {
  clientName: "sba-sortis",
  env: "production",
  product: ["auth", "identity", "transactions"],
  publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY
};

export const plaidPayroll = {
  clientName: "sba-sortis",
  env: "production",
  product: ["sba_verification"],
  publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY
};
