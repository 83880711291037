import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  spacing: 1,
  palette: {
    primary: {
      main: "#006BD6"
    },
    secondary: {
      main: "#6DDFDF"
    }
  },
  status: {
    danger: "orange"
  },
  typography: {
    subtitle1: {
      fontSize: 16
    },
    h3: {
      fontSize: 34
    },
    h4: {
      fontSize: 24
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: "15px",
        paddingRight: "15px"
      }
    },
    MuiLinearProgress: {
      root: {
        height: "8px",
        borderRadius: "5px"
      },
      bar: {
        borderRadius: "5px"
      }
    },
    MuiFormControlLabel: {
      root: {
        alignItems: "flex-start",
        marginRight: "4px",
        whiteSpace: "pre-wrap"
      },
      label: {
        marginLeft: "5px"
      }
    }
  }
});
