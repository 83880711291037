import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Box,
  FormControl
} from "@material-ui/core";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

import copy from "./copy.json";

const BasicInfo = ({ back, next, setInputs: setFormInputs, nextStep }) => {
  const [inputs, setInputs] = useState({});

  const handleInputChange = e => {
    e.persist();
    if (e.target.name === "zipcode" && e.target.value !== "") {
      if (e.target.value && e.target.value.length > 5) {
        return;
      }
      if (/^[0-9]*$/.test(e.target.value) === false) return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const goForward = () => {
    setFormInputs(inputs);
    nextStep();
  };

  const isProgressDisabled =
    !inputs.email ||
    !inputs.firstName ||
    !inputs.lastName ||
    !inputs.zipcode ||
    !inputs.tel;

  return (
    <Layout centered>
      <Container maxWidth="sm">
        <Box mb={20}>
          <Typography variant="h4">{copy.heading}</Typography>
        </Box>
        <Typography variant="body1">{copy.body}</Typography>
        <Box mt={30}>
          <Grid container style={{ maxWidth: "400px" }}>
            <Typography variant="caption" color="error">
              These need to match the personal details your bank has on file.
            </Typography>
            <Grid item xs={12}>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="firstName"
                  name="firstName"
                  required
                  label="first name"
                  value={inputs.firstName || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="lastName"
                  name="lastName"
                  required
                  label="last name"
                  value={inputs.lastName || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="email"
                  name="email"
                  required
                  label="email"
                  type="email"
                  value={inputs.email || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="zipcode"
                  name="zipcode"
                  required
                  label="5 digit zipcode"
                  type="text"
                  pattern="[0-9]*"
                  value={inputs.zipcode || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="tel"
                  name="tel"
                  required
                  label="telephone number"
                  type="tel"
                  value={inputs.tel || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <FormNavButtons>
          <FormNavButton back={back}></FormNavButton>
          <FormNavButton
            next={next}
            disabled={isProgressDisabled}
            nextFunc={goForward}
          ></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default BasicInfo;
