import React from "react";
import { Container } from "@material-ui/core";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

const SectionIntro = ({ children, next, back, previousStep }) => {
  return (
    <Layout centered>
      <Container maxWidth="sm">
        {children}
        <FormNavButtons>
          <FormNavButton
            previousFunc={previousStep}
            back={back}
          ></FormNavButton>
          <FormNavButton next={next}></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default SectionIntro;
