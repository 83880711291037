import firebase from "../config/firebase";
import { BASE_URL } from "./helpers";

async function getAuthToken() {
  const currentUser = await firebase.auth().currentUser;
  if (!currentUser) return null;
  return currentUser.getIdToken();
}

export async function testFunc() {
  let token = await getAuthToken();
  if (!token) token = "test should fail";
  const res = await fetch(`${BASE_URL}/initUser`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await res.json();
  console.log("test fetch", data);
}

export async function checkShutDown() {
  const res = await fetch(
    "https://us-central1-sortis-sba.cloudfunctions.net/checkShutDown"
  );
  return res.json();
}

export async function testEmail() {
  if (process.env.NODE_ENV !== "development") return;
  let token = await getAuthToken();
  if (!token) token = "test should fail";
  const res = await fetch(`${BASE_URL}/testEmail`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  const data = await res.json();
  console.log("test fetch", data);
}

export async function getAccessToken(payload) {
  const token = await getAuthToken();
  if (!token) throw Error("user is not authenticated");
  const res = await fetch(`${BASE_URL}/get-access-token`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (!res.ok) {
    const err = await res.json();
    throw Error(err.message);
  }
  return res.json();
}

export async function checkIdentity(payload) {
  const token = await getAuthToken();
  if (!token) throw Error("user is not authenticated");
  const res = await fetch(`${BASE_URL}/verify-identity`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (!res.ok) {
    const err = await res.json();
    throw Error(err.message);
  }

  const pass = await res.json();
  return pass;
}

export async function getPayrollData(payload) {
  const token = await getAuthToken();
  if (!token) throw Error("user is not authenticated");
  const res = await fetch(`${BASE_URL}/verify-payroll`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (!res.ok) {
    const err = await res.json();
    throw Error(err.message);
  }

  return res.json();
}

export async function submitFormBasic(payload) {
  // this just saves the user inputs to our database and triggers and email with a json file attached
  // our customer service team then does something manual with the data
  const token = await getAuthToken();
  if (!token) throw Error("user is not authenticated");
  const res = await fetch(`${BASE_URL}/submit`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (!res.ok) {
    const err = await res.json();
    throw Error(err.message);
  }

  const data = await res.json();
  return data;
}

export async function submitFormAdvanced(payload) {
  // this saves the data AND hits the
  const token = await getAuthToken();
  if (!token) throw Error("user is not authenticated");
  const res = await fetch(`${BASE_URL}/submitAdvanced`, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (!res.ok) {
    const err = await res.json();
    throw Error(err.message);
  }

  const data = await res.json();
  return data;
}
