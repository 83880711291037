import React from "react";
import { Button, Box } from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

export const FormNavButtons = ({ children }) => (
  <Box component="div" className="BottomNavButtons">
    <div className="ButtonGroup">{children}</div>
  </Box>
);

export const FormNavButton = ({
  next,
  back,
  previousFunc,
  nextFunc,
  disabled,
  loading
}) => (
  <>
    <Button
      startIcon={back || previousFunc ? <ChevronLeft /> : ""}
      size="large"
      disabled={disabled}
      variant={back || previousFunc ? "" : "contained"}
      color="primary"
      href={next || back || ""}
      style={{ width: next || nextFunc ? "200px" : "inherit" }}
      onClick={() =>
        previousFunc ? previousFunc() : nextFunc ? nextFunc() : {}
      }
    >
      {next || nextFunc ? "Next" : "Back"}
    </Button>
  </>
);
