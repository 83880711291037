import React, { PureComponent } from "react";
import {
  Container,
  Typography,
  LinearProgress,
  Box,
  Paper
} from "@material-ui/core";
import PlaidLinkIdentity from "../../components/PlaidLinkIdentity";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";
import copy from "./copy.json";

class VerifyIdentity extends PureComponent {
  state = {
    displayVerification: false,
    isWorking: false
  };

  componentDidUpdate(prevProps) {
    const { isLoading, identityError } = this.props;
    const {
      isLoading: prevIsLoading,
      identityError: prevIdentityError
    } = prevProps;
    if (!isLoading && prevIsLoading) {
      this.setState({
        displayVerification: true,
        isWorking: false
      });
    }
    // if (!!identityError && prevIdentityError === null) {
    //   this.setState
    // }
  }

  moveForward = () => {
    const { nextStep, addPlaidLink } = this.props;
    addPlaidLink();
    nextStep();
  };

  displayWorking = () => {
    this.setState({
      isWorking: !this.state.isWorking
    });
  };

  render() {
    const {
      next,
      back,
      isLoading,
      callback,
      hasPassed,
      identityError,
      payrollLinked
    } = this.props;

    const isDisabledNext = hasPassed ? false : payrollLinked ? false : true;
    const localLoading = isLoading || this.state.isWorking;

    return (
      <Layout centered>
        <Container maxWidth="sm">
          <Box>
            <Box mb={20}>
              <Typography variant="h4">{copy.heading}</Typography>
            </Box>
            <Box style={{ whiteSpace: "pre-wrap" }}>
              <Typography variant="body1">{copy.subheading}</Typography>
            </Box>
          </Box>
          <Box
            my={40}
            style={{
              display:
                localLoading || hasPassed || payrollLinked ? "none" : "block"
            }}
          >
            <PlaidLinkIdentity
              callback={callback}
              handoff={this.displayWorking}
            />
          </Box>
          {localLoading && !this.state.displayVerification && (
            <Paper elevation={3}>
              <Box
                mt={30}
                px={15}
                py={25}
                display="flex"
                flexDirection="column"
              >
                <Box>
                  <LinearProgress color="primary" />
                </Box>
                <Box mt={15}>
                  <Typography color="primary" variant="body2">
                    Verifying your identity...
                  </Typography>
                </Box>
              </Box>
            </Paper>
          )}
          {this.state.displayVerification && !payrollLinked && (
            <Paper elevation={3}>
              <Box mt={30} px={15} py={25}>
                {hasPassed ? (
                  <Typography color="primary" variant="body1">
                    We have verified your identity. Please proceed.
                  </Typography>
                ) : (
                  <Typography style={{ color: "red" }} variant="body1">
                    Sorry we couldn't verify your identity. Try changing your
                    details on the previous screen.
                  </Typography>
                )}
              </Box>
            </Paper>
          )}
          <FormNavButtons>
            <FormNavButton back={back}></FormNavButton>
            <FormNavButton
              disabled={isDisabledNext}
              nextFunc={this.moveForward}
              next={next}
            ></FormNavButton>
          </FormNavButtons>
        </Container>
      </Layout>
    );
  }
}

export default VerifyIdentity;
