import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  FormControl
} from "@material-ui/core";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

import copy from "./copy.json";

const Disqualification = ({
  back,
  next,
  setInputs: setFormInputs,
  nextStep
}) => {
  const [disqual, setDisqual] = useState(false);

  const handleCheckboxChange = e => {
    e.persist();
    setDisqual(e.target.checked);
  };

  const goForward = () => {
    setFormInputs({ passedDisqual: disqual });
    nextStep();
  };

  const isProgressDisabled = disqual === false || disqual === null;

  return (
    <Layout centered>
      <Container maxWidth="sm">
        <Typography variant="h4">
          Eligibility Requirements - Disqualifying Criteria
        </Typography>
        <Box my={20}>
          <Typography variant="body1">
            If any of the following criteria are true the loan{" "}
            <span style={{ fontWeight: 700, color: "red" }}>cannot</span> be
            approved:
          </Typography>
        </Box>
        <Box my={40}>
          {copy.blocks.map((block, i) => (
            <Box key={i} my={20}>
              <Typography variant="body1">{block}</Typography>
            </Box>
          ))}
        </Box>
        <Box mt={30}>
          <Grid container style={{ maxWidth: "400px" }}>
            <Grid item xs={12}>
              <FormControl>
                <FormControlLabel
                  style={{ display: "flex", alignItem: "flex-start" }}
                  control={
                    <Checkbox
                      checked={disqual}
                      onChange={e => handleCheckboxChange(e)}
                      name="agreeTerms"
                      color="secondary"
                    />
                  }
                  label={<Typography variant="body2">{copy.label}</Typography>}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <FormNavButtons>
          <FormNavButton back={back}></FormNavButton>
          <FormNavButton
            next={next}
            disabled={isProgressDisabled}
            nextFunc={goForward}
          ></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default Disqualification;
