import React from "react";
import {
  Box,
  Container,
  Button,
  Typography,
  LinearProgress
} from "@material-ui/core";
import { CheckCircle, Error } from "@material-ui/icons";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

import copy from "./copy.json";
import successImage from "../../assets/sortis-sba-success.png";

const Result = ({
  next,
  back,
  isLoadingSubmit,
  hasSuccessfullySubmitted,
  submitError,
  customerTracking,
  loanNumber
}) => {
  return (
    <Layout centered>
      <Container maxWidth="sm">
        <Box></Box>
        {isLoadingSubmit ? (
          <Box
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              width: "400px",
              textAlign: "center",
              transform: "translate(-50%,-50%)"
            }}
          >
            <Box style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              <Box>
                <Typography variant="h5">Submitting Application</Typography>
              </Box>
              <Box mt={30}>
                <LinearProgress color="primary" />
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            {hasSuccessfullySubmitted && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box pl={15}>
                  <img
                    style={{ maxWidth: "80px" }}
                    src={successImage}
                    alt="success"
                  />
                </Box>
                <Box mt={-15}>
                  <CheckCircle style={{ fontSize: 80, color: "#03dac5" }} />
                </Box>
                <Box>
                  <Typography variant="h5">Success</Typography>
                </Box>
                <Box mt={30}>
                  <div
                    style={{
                      height: "3px",
                      width: "260px",
                      background: "#6DDFDF",
                      margin: "auto"
                    }}
                  />
                  <Box mt={30} textAlign="center">
                    <Typography variant="body1">
                      SBA Reservation Number
                    </Typography>
                  </Box>
                  <Box mt={20} textAlign="center">
                    <Typography
                      variant="h6"
                      color="primary"
                      style={{ letterSpacing: "0.25px" }}
                    >
                      {loanNumber}
                    </Typography>
                  </Box>
                  <Box mt={50}>
                    <Typography variant="h4">
                      We have reserved your funds, electronically, through the
                      SBA!
                    </Typography>
                  </Box>
                  <Box mt={30} style={{ whiteSpace: "pre-wrap" }}>
                    <Typography variant="body1">{copy.successCopy}</Typography>
                  </Box>
                  <Box mt={30}>
                    <Typography variant="caption" color="primary">
                      Your Customer ID Number: {customerTracking}
                    </Typography>
                  </Box>
                  <Box mt={40} textAlign="center">
                    <Button color="primary" href="https://sba.sortis.com">
                      Take me back to sba.sortis.com
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {!!submitError && (
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Error style={{ fontSize: 100, color: "red" }} />
                  </Box>
                  <Box>
                    <Typography variant="h5">Bummer</Typography>
                  </Box>
                  <Box mt={30}>
                    <div
                      style={{
                        height: "3px",
                        width: "260px",
                        background: "#6DDFDF",
                        margin: "auto"
                      }}
                    />
                    <Box mt={30}>
                      <Typography variant="h6">
                        The SBA electronic system appears to be experiencing
                        issues.
                      </Typography>
                    </Box>
                    <Box mt={30}>
                      <Typography variant="body1">
                        Please contact us via the phone number below. Please be
                        ready to give us your Customer ID Number:{" "}
                        <b>{customerTracking}</b>.
                      </Typography>
                    </Box>
                    <Box mt={30}>
                      <Typography variant="body1">
                        If you qualify, we may be able to help you submit
                        manually, or try to resubmit electronically, once the
                        SBA system comes back online.
                      </Typography>
                    </Box>
                    <Box mt={30}>
                      <Typography color="primary" variant="body1">
                        Customer Service: 1-800-Sortis-Phone
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}
        <FormNavButtons>
          <FormNavButton back={back}></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default Result;
