import React, { PureComponent, createContext } from "react";
import firebase, { emailLinkSettings } from "../../config/firebase";
import { isFunction } from "lodash";

const INITIAL_STATE = {
  uid: "",
  email: "",
  isAuthenticated: false,
  hasEmailLinkSent: false,
  hasEmailLinkErrored: false,
  isLoadingAuthListener: true
};

export const AuthContext = createContext();

class AuthProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  async componentDidMount() {
    // this.signOut();
    this.setAuthListener();
    this.checkAuthLinkAndSignin();
  }

  componentWillUnmount() {
    isFunction(this.stopAuthListener) && this.stopAuthListener();
  }

  setAuthListener = () => {
    const auth = firebase.auth();
    this.stopAuthListener = auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        // if user exists get user object from firestore
        try {
          if (window.intercomSettings) {
            window.intercomSettings.email = authUser.email;
          }
          this.setState({
            isAuthenticated: true,
            isLoadingAuthListener: false,
            uid: authUser.uid,
            email: authUser.email
          });
        } catch (error) {
          this.setState({
            errorFetchUser: error.message,
            isLoadingAuthListener: false
          });
          console.error(error);
        }
      } else {
        // is not authenticated
        this.setState({ isLoadingAuthListener: false });
      }
    });
  };

  signOut = async () => {
    console.log("signing out");
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ ...INITIAL_STATE, isLoadingAuth: false });
      })
      .catch(error => {
        console.error(error);
      });
  };

  checkAuthLinkAndSignin = () => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      if (!email) return;
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          if (window.intercomSettings) {
            window.intercomSettings.email = email;
          }
        })
        .catch(function(error) {
          console.error(error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  };

  sendAuthLink = email => {
    if (!email) return;
    try {
      firebase
        .auth()
        .sendSignInLinkToEmail(email, emailLinkSettings)
        .then(() => {
          this.setState({ hasEmailLinkSent: true });
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem("emailForSignIn", email);
        })
        .catch(error => {
          console.error("error magic link", error);
          this.setState({ hasEmailLinkErrored: true });
        });
    } catch (error) {
      console.error("error magic link", error);
      this.setState({ hasEmailLinkErrored: true });
    }
  };

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          sendAuthLink: this.sendAuthLink
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
