import React, { useState } from "react";
import {
  Box,
  Container,
  Button,
  Typography,
  // Grid,
  TextField,
  // Snackbar,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";

import copy from "./copy.json";

const CompleteForm = ({ next, back, submitForm, nextStep, setInputs }) => {
  const [agreeTerms, setAgreeTerms] = useState({});
  const [confirmName, setConfirmName] = useState("");
  const [confirmDate, setConfirmDate] = useState("");

  const handleCheckboxChange = e => {
    e.persist();
    setAgreeTerms({
      ...agreeTerms,
      [e.target.name]: e.target.checked
    });
  };

  const handleNameChange = e => {
    e.persist();
    setConfirmName(e.target.value);
  };

  const handleDateChange = e => {
    e.persist();
    setConfirmDate(e.target.value);
  };

  const handleComplete = async () => {
    await new Promise(resolve =>
      resolve(
        setInputs({
          signedName: confirmName,
          signedDate: confirmDate,
          agreeFinalTerms: true
        })
      )
    );
    submitForm();
    nextStep();
  };

  const isDisabled =
    !!agreeTerms["agreeTermsFirst"] &&
    !!agreeTerms["agreeTermsSecond"] &&
    !!agreeTerms["agreeTermsThird"] &&
    !!confirmName &&
    !!confirmDate;

  return (
    <Layout>
      <Box>
        <Container maxWidth="sm">
          <Box>
            <Box mb={20}>
              <Typography variant="h4">{copy.heading}</Typography>
            </Box>
            <Box>
              <Typography variant="h6">{copy.subheading}</Typography>
            </Box>
            <Box mt={40}>
              <Box my={30}>
                <FormControl>
                  <FormControlLabel
                    style={{ display: "flex", alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        checked={
                          (agreeTerms && agreeTerms.agreeTermsFirst) || false
                        }
                        onChange={e => handleCheckboxChange(e)}
                        name="agreeTermsFirst"
                        color="primary"
                      />
                    }
                    label={copy.label1}
                  />
                </FormControl>
              </Box>
              <Box my={30}>
                <FormControl>
                  <FormControlLabel
                    style={{ display: "flex", alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        checked={
                          (agreeTerms && agreeTerms.agreeTermsSecond) || false
                        }
                        onChange={e => handleCheckboxChange(e)}
                        name="agreeTermsSecond"
                        color="primary"
                      />
                    }
                    label={copy.label2}
                  />
                </FormControl>
              </Box>
              <Box my={30}>
                <FormControl>
                  <FormControlLabel
                    style={{ display: "flex", alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        checked={
                          (agreeTerms && agreeTerms.agreeTermsThird) || false
                        }
                        onChange={e => handleCheckboxChange(e)}
                        name="agreeTermsThird"
                        color="primary"
                      />
                    }
                    label={copy.label3}
                  />
                </FormControl>
              </Box>
              <Box mt={40}>
                <FormControl margin="normal">
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="confirmName"
                    name="confirmName"
                    required
                    label="Your full name"
                    value={confirmName || ""}
                    onChange={handleNameChange}
                  />
                </FormControl>
                <FormControl margin="normal">
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="confirmDate"
                    name="confirmDate"
                    type="date"
                    required
                    label="Today's date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={confirmDate || ""}
                    onChange={handleDateChange}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box mt={40}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!isDisabled}
              onClick={handleComplete}
            >
              SUBMIT
            </Button>
          </Box>
          <FormNavButtons>
            <FormNavButton back={back}></FormNavButton>
          </FormNavButtons>
        </Container>
      </Box>
      {/* <Box mt={40}>
        <Snackbar
          open={hasEmailLinkSent || hasEmailLinkErrored}
          autoHideDuration={6000}
        >
          {hasEmailLinkSent ? (
            <Alert severity="success">
              A sign in link has been sent to the email address provided.
            </Alert>
          ) : (
            <Alert severity="error">
              Sorry, there was a problem sending a link to your email address.
              Please try again.
            </Alert>
          )}
        </Snackbar>
      </Box> */}
    </Layout>
  );
};

export default CompleteForm;
