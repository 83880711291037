import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  Grid,
  Box,
  FormControl,
  Paper
} from "@material-ui/core";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";
import copy from "./copy.json";

const PayrollInputs = ({
  back,
  next,
  setInputs: setFormInputs,
  nextStep,
  payrollAmount,
  employeeCount,
  prevLoanAmount
}) => {
  const [inputs, setInputs] = useState({});
  const [loanAmount, setLoanAmount] = useState(0);

  useEffect(() => {
    function calcLoanAmount() {
      const total = parseFloat(payrollAmount) * 2.5 - 0;
      return total;
    }
    setInputs({
      payrollAverage: payrollAmount,
      employeeCount: employeeCount,
      EIDLAmount: prevLoanAmount
    });
    setLoanAmount(calcLoanAmount());
  }, [payrollAmount, employeeCount, prevLoanAmount]);

  const handleInputChange = e => {
    e.persist();
    if (parseInt(e.target.value) < 0) return;
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });

    if (e.target.name === "payrollAverage") {
      setLoanAmount(calcLoanAmount(e.target.value));
    }
    if (e.target.name === "EIDLAmount") {
      setLoanAmount(calcLoanAmount(null, e.target.value));
    }
  };

  const goForward = () => {
    setFormInputs({
      payrollAmount: inputs.payrollAverage,
      prevLoanAmount: inputs.EIDLAmount,
      PPPLoanAmount: loanAmount,
      employeeCount: inputs.employeeCount
    });
    nextStep();
  };

  const calcLoanAmount = (payrollChange, EIDLChange) => {
    const payrollAmount = parseFloat(
      payrollChange || inputs.payrollAverage || 0
    );
    const EIDLAmount = parseFloat(EIDLChange || inputs.EIDLAmount || 0);
    const total = payrollAmount * 2.5 - EIDLAmount;
    return total < 0 ? 0 : total;
  };

  const isProgressDisabled = !inputs.payrollAverage || !inputs.employeeCount;

  return (
    <Layout centered>
      <Container maxWidth="sm">
        <Box>
          <Box mb={20}>
            <Typography variant="h4">{copy.heading}</Typography>
          </Box>
          <Box>
            <Typography variant="body1">{copy.subheading}</Typography>
          </Box>
        </Box>

        <Box mt={40}>
          <Grid container spacing={2} style={{ maxWidth: "400px" }}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="body1">Average monthly payroll</Typography>
              </Box>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  id="payrollAverage"
                  variant="outlined"
                  name="payrollAverage"
                  required
                  type="number"
                  label="Average monthly payroll"
                  value={inputs.payrollAverage || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <Box mt={30}>
                <Typography variant="body1">
                  Economic Injury Disaster Loan (EIDL)
                </Typography>
                <Typography variant="body2">
                  Have you already received an Economic Injury Disaster Loan? If
                  so, enter that amount here. Otherwise, leave it blank.
                </Typography>
              </Box>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  id="EIDLAmount"
                  name="EIDLAmount"
                  variant="outlined"
                  required
                  type="number"
                  label="EIDL amount"
                  value={inputs.EIDLAmount || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <Box mt={30}>
                <Typography variant="body1">
                  Number of employees on 2/15/2020
                </Typography>
              </Box>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  id="employeeCount"
                  name="employeeCount"
                  variant="outlined"
                  required
                  type="number"
                  min={0}
                  label="Number of employees"
                  value={inputs.employeeCount || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={40}>
            <Box>
              <Typography variant="h6">Calculated Loan Amount</Typography>
            </Box>
            <Box mb={20}>
              <Typography variant="body2">
                Average monthly payroll * 2.5 - EIDL
              </Typography>
            </Box>
            <Box>
              <Typography color="primary" variant="h4">
                {loanAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={80}>
          <Paper elevation={0}>
            <Box px={15} py={20}>
              <Typography variant="body2">
                If you need to calculate the amount manually, here's that link
                again to{" "}
                <a
                  href="https://www.aicpa.org/interestareas/privatecompaniespracticesection/qualityservicesdelivery/sba-paycheck-protection-program-resources-for-cpas.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#1c529c" }}
                >
                  AICPA's methodology
                </a>
                .
              </Typography>
            </Box>
          </Paper>
        </Box>

        <FormNavButtons>
          <FormNavButton back={back}></FormNavButton>
          <FormNavButton
            next={next}
            disabled={isProgressDisabled}
            nextFunc={goForward}
          ></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default PayrollInputs;
