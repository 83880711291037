import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box
} from "@material-ui/core";
import Layout from "../../components/Layout";
import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";
import { mapStatesToOptions } from "../../util/helpers";
import { isEmpty } from "lodash";

import copy from "./copy.json";

const BusinessInfo2 = ({ back, next, setInputs: setFormInputs, nextStep }) => {
  const [inputs, setInputs] = useState({});

  const handleInputChange = e => {
    e.persist();
    if (
      e.target.name === "businessAddressZipcode" ||
      (e.target.name === "businessAddressZipcodeFourDigit" &&
        e.target.value !== "")
    ) {
      if (/^[0-9]*$/.test(e.target.value) === false) {
        return;
      }

      if (
        (e.target.name === "businessAddressZipcode" &&
          e.target.value &&
          e.target.value.length > 5) ||
        (e.target.name === "businessAddressZipcodeFourDigit" &&
          e.target.value &&
          e.target.value.length > 4)
      ) {
        return;
      }
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const handleSelectChange = e => {
    e.persist();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxChange = e => {
    e.persist();
    setInputs({
      ...inputs,
      loanPurpose: {
        ...inputs.loanPurpose,
        [e.target.name]: e.target.checked
      }
    });
  };

  const goForward = () => {
    setFormInputs(inputs);
    nextStep();
  };

  const hasCompletedLoanPurpose =
    !isEmpty(inputs.loanPurpose) &&
    Object.keys(inputs.loanPurpose).some(key => {
      return inputs.loanPurpose[key] === true;
    });

  const isProgressDisabled =
    !inputs.businessAddressLine1 ||
    !inputs.businessAddressCity ||
    !inputs.businessAddressState ||
    !inputs.businessAddressZipcode ||
    !inputs.businessAddressZipcodeFourDigit ||
    !inputs.businessPrimaryTel ||
    !hasCompletedLoanPurpose;

  return (
    <Layout>
      <Container maxWidth="sm">
        <Box mb={20}>
          <Typography variant="h4">{copy.heading}</Typography>
        </Box>
        <Box mb={20}>
          <Typography variant="body1">{copy.subheading}</Typography>
        </Box>
        <Box style={{ maxWidth: "400px" }}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl margin="normal">
                <TextField
                  variant="outlined"
                  fullWidth
                  id="businessAddressLine1"
                  name="businessAddressLine1"
                  required
                  label="Address line 1"
                  value={inputs.businessAddressLine1 || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="businessAddressLine2"
                  name="businessAddressLine2"
                  label="Address line 2"
                  value={inputs.businessAddressLine2 || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl margin="normal">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="businessAddressCity"
                  name="businessAddressCity"
                  required
                  label="City"
                  value={inputs.businessAddressCity || ""}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl margin="normal" variant="outlined">
                <InputLabel id="businessAddressStateLabel">State</InputLabel>
                <Select
                  fullWidth
                  labelId="businessAddressStateLabel"
                  name="businessAddressState"
                  id="businessAddressState"
                  value={inputs.businessAddressState || ""}
                  onChange={handleSelectChange}
                  label="State"
                >
                  {mapStatesToOptions().map(opt => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box mt={20}>
            <Typography variant="body2">Full 9 digit zipcode</Typography>
          </Box>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={7}>
              <FormControl margin="normal">
                <TextField
                  variant="outlined"
                  id="businessAddressZipcode"
                  name="businessAddressZipcode"
                  required
                  label="5 digit zip"
                  style={{ marginRight: "30px" }}
                  value={inputs.businessAddressZipcode || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1} style={{ textAlign: "center" }}>
              <FormControl margin="normal">
                <Typography variant="body2">-</Typography>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl margin="normal">
                <TextField
                  variant="outlined"
                  id="businessAddressZipcodeFourDigit"
                  name="businessAddressZipcodeFourDigit"
                  required
                  label="4 digit zip"
                  value={inputs.businessAddressZipcodeFourDigit || ""}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl margin="normal">
            <TextField
              fullWidth
              variant="outlined"
              id="businessPrimaryTel"
              name="businessPrimaryTel"
              type="tel"
              required
              label="Business primary telephone"
              value={inputs.businessPrimaryTel || ""}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">
              What is the purpose of your loan?
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                style={{ display: "flex", alignItems: "center" }}
                control={
                  <Checkbox
                    checked={
                      (inputs.loanPurpose && inputs.loanPurpose.payroll) || ""
                    }
                    onChange={handleCheckboxChange}
                    name="payroll"
                  />
                }
                label="Payroll"
              />
              <FormControlLabel
                style={{ display: "flex", alignItems: "center" }}
                control={
                  <Checkbox
                    checked={
                      (inputs.loanPurpose &&
                        inputs.loanPurpose.leaseMortgageInterest) ||
                      ""
                    }
                    onChange={handleCheckboxChange}
                    name="leaseMortgageInterest"
                  />
                }
                label="Lease / Mortgage interest"
              />
              <FormControlLabel
                style={{ display: "flex", alignItems: "center" }}
                control={
                  <Checkbox
                    checked={
                      (inputs.loanPurpose && inputs.loanPurpose.utilities) || ""
                    }
                    onChange={handleCheckboxChange}
                    name="utilities"
                  />
                }
                label="Utilities"
              />
              <FormControlLabel
                style={{ display: "flex", alignItems: "center" }}
                control={
                  <Checkbox
                    checked={
                      (inputs.loanPurpose && inputs.loanPurpose.other) || ""
                    }
                    onChange={handleCheckboxChange}
                    name="other"
                  />
                }
                label="Other"
              />
            </FormGroup>

            <FormHelperText>Select all that apply</FormHelperText>
          </FormControl>
        </Box>
        {/* </Grid> */}

        <FormNavButtons>
          <FormNavButton back={back}></FormNavButton>
          <FormNavButton
            next={next}
            disabled={isProgressDisabled}
            nextFunc={goForward}
          ></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default BusinessInfo2;
