import React from "react";

const Layout = ({ children, centered = false, scrolling = true }) => (
  <div
    className={`Layout ${centered ? "Centered" : ""} ${
      scrolling ? "Scrolling" : ""
    }`}
  >
    {children}
  </div>
);

export default Layout;
