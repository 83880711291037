import React, { useContext, useState } from "react";
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  TextField,
  Snackbar,
  FormControl,
  FormControlLabel,
  Checkbox,
  Divider,
  LinearProgress
} from "@material-ui/core";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Alert from "@material-ui/lab/Alert";
import Layout from "../../components/Layout";
import { AuthContext } from "../../components/Authentication";

import sortisPayroll from "../../assets/sortis-sba-payroll.png";
import sortisMoney from "../../assets/sortis-sba-money.png";
import copy from "./copy.json";

const Welcome = ({ next, shutoff }) => {
  const {
    isAuthenticated,
    hasEmailLinkSent,
    sendAuthLink,
    hasEmailLinkErrored,
    isLoadingAuthListener
  } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleInputChange = e => {
    e.persist();
    setEmail(e.target.value);
  };

  const handleCheckboxChange = e => {
    e.persist();
    setAgreeTerms(e.target.checked);
  };

  const copyBlock = (
    <Box>
      <Box>
        <Typography variant="h4">{copy.heading2}</Typography>
      </Box>
      <Box mt={20}>
        {copy.benefits.map(benefit => (
          <Box key={benefit.heading} my={20}>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box mr={15}>
                <CheckCircleOutline
                  fontSize="default"
                  style={{ color: "#03dac5" }}
                />
              </Box>
              <Typography variant="subtitle1">{benefit.heading}</Typography>
            </Box>
            <Box ml={38} mt={4}>
              <Typography
                variant="body1"
                style={{
                  color: "rgba(0, 0, 0, 0.74)",
                  fontSize: "14px"
                }}
              >
                {benefit.copy}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box mt={50} mb={20}>
        <Typography variant="h4">{copy.heading3}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle1">{copy.subheading3}</Typography>
      </Box>
      <Box mt={30}>
        {copy.prep.map(item => (
          <Box key={item.heading} my={20}>
            <Box>
              <Typography
                variant="body2"
                style={{ color: "rgba(0, 0, 0, 0.6)" }}
              >
                {item.heading}
              </Typography>
            </Box>
            <Box mt={5} mb={12}>
              <Typography variant="subtitle1">{item.copy}</Typography>
            </Box>
            <Divider />
          </Box>
        ))}
      </Box>
    </Box>
  );

  const readyBlock = (
    <Box py={32} textAlign="center" style={{ background: "#6ddfdf" }}>
      <Box mb={30}>
        <Typography variant="h3">{copy.heading4}</Typography>
      </Box>
      <Box>
        <img src={sortisPayroll} className="PayrollImage" alt="welcome" />
      </Box>
      <Box pt={40}>
        <Typography variant="h3">Let's get started</Typography>
      </Box>
    </Box>
  );

  return (
    <>
      {isLoadingAuthListener ? (
        <Box className="WelcomeLoader">
          <LinearProgress />
        </Box>
      ) : shutoff ? (
        <Layout centered>
          <Container maxWidth="sm">
            <Box mb={20} textAlign="center">
              <Typography variant="h3">
                We're sorry, we're not taking any more applications at this
                time.
              </Typography>
            </Box>
            <Box textAlign="center">
              <img src={sortisPayroll} className="PayrollImage" alt="welcome" />
            </Box>
          </Container>
        </Layout>
      ) : (
        <>
          <Box className="WelcomeIntro">
            <Container maxWidth="sm">
              <Box mb={20}>
                <Typography style={{ fontWeight: 700 }} variant="h3">
                  {copy.heading}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">{copy.subheading}</Typography>
              </Box>
              <Box mt={20}>
                <img
                  src={sortisMoney}
                  style={{ width: "100%", maxWidth: "350px" }}
                  alt="welcome"
                />
              </Box>
            </Container>
          </Box>

          {isAuthenticated ? (
            <>
              <Box>
                <Box>
                  <Layout>
                    <Box>
                      <Container maxWidth="sm">
                        <Box textAlign="center">
                          <Typography variant="h3">
                            Let's get started
                          </Typography>
                        </Box>
                        <Box mt={40} mb={100}>
                          <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            href={next}
                          >
                            Start your application
                          </Button>
                        </Box>

                        {copyBlock}
                      </Container>
                    </Box>
                  </Layout>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Layout>
                <Box>
                  <Container maxWidth="sm">{copyBlock}</Container>
                  <Box>
                    {readyBlock}
                    <Container maxWidth="sm">
                      <Box mt={50} style={{ whiteSpace: "pre-wrap" }}>
                        <Typography variant="body1">
                          {copy.copyNoAuth}
                        </Typography>
                      </Box>
                      <Box mt={30}>
                        <Grid
                          container
                          spacing={2}
                          style={{ maxWidth: "400px" }}
                        >
                          <Grid item xs={12}>
                            <FormControl margin="normal">
                              <TextField
                                fullWidth
                                id="email"
                                name="email"
                                variant="outlined"
                                required
                                label="email"
                                type="email"
                                value={email || ""}
                                onChange={handleInputChange}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Box mt={5}>
                          <FormControl>
                            <FormControlLabel
                              style={{
                                display: "flex",
                                alignItems: "center"
                              }}
                              control={
                                <Checkbox
                                  checked={agreeTerms}
                                  onChange={e => handleCheckboxChange(e)}
                                  name="agreeTerms"
                                  color="primary"
                                />
                              }
                              label={
                                <Typography variant="body2">
                                  {copy.agreeTerms}
                                </Typography>
                              }
                            />
                          </FormControl>
                        </Box>
                        <Box my={60}>
                          <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!agreeTerms || !email}
                            onClick={() => sendAuthLink(email)}
                          >
                            Send link
                          </Button>
                        </Box>
                      </Box>
                    </Container>
                  </Box>
                </Box>
              </Layout>
            </>
          )}

          <Box pt={40}>
            <Snackbar
              open={hasEmailLinkSent || hasEmailLinkErrored}
              autoHideDuration={6000}
            >
              {hasEmailLinkSent ? (
                <Alert severity="success">
                  A sign in link has been sent to the email address provided.
                </Alert>
              ) : (
                <Alert severity="error">
                  Sorry, there was a problem sending a link to your email
                  address. Please try again.
                </Alert>
              )}
            </Snackbar>
          </Box>
        </>
      )}
    </>
  );
};

export default Welcome;
