import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET
} = process.env;
const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET
};

export default firebase.initializeApp(config);

export const emailLinkSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/"
      : "https://sba-app.sortis.com/",
  // This must be true.
  handleCodeInApp: true
  // iOS: {
  //   bundleId: "com.example.ios"
  // },
  // android: {
  //   packageName: "com.example.android",
  //   installApp: true,
  //   minimumVersion: "12"
  // },
  // dynamicLinkDomain: "http://localhost:3000/"
};
