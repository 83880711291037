export default {
  1: {
    heading: "Time in Operation",
    copy:
      "Applicant was in operation on February 15, 2020 and had employees for whom the Applicant paid salaries and payroll taxes or paid independent contractors, as reported on Form(s) 1099- MISC ",
    name: "verifyTimeInOperation"
  },
  2: {
    heading: "Economic Uncertainty",
    copy:
      "Current economic uncertainty makes this loan request necessary to support the ongoing operations of the Applicant",
    name: "verifyEconomicUncertainty"
  },
  3: {
    heading: "Use of Funds",
    copy:
      "The funds will be used to retain workers and maintain payroll or make mortgage interest payments, lease payments, and utility payments",
    name: "verifyUseOfFunds"
  },
  4: {
    heading: "Has not received another Paycheck Protection Program loan",
    copy:
      "During the period beginning on February 15, 2020 and ending on December 31, 2020, the Applicant has not and will not receive another loan under the Paycheck Protection Program. ",
    name: "verifyNoAdditionalPPPLoan"
  },
  5: {
    heading: "Qualified Business Type / Entity",
    copy: `The Applicant is an independent contractor, eligible self-employed individual, or sole proprietor.\n\nOR\n\nThe applicant employs no more than the greater of 500 employees or, if applicable, the size standard in number of employees established by the SBA in 13 C.F.R. 121.201 for the Applicant’s industry.\n\nAND\n\nIf franchise: The Applicant has represented to the Lender that it is a franchise that is listed in the SBA’s Franchise Directory.`,
    name: "verifyQualifiedBusiness"
  },
  6: {
    heading:
      "Principal Place of residence for all employees is the United States",
    copy:
      "The Applicant has certified that the principal place of residence for all employees included in the Applicant’s payroll calculation is the United States.",
    name: "verifyPrinciplePlaceOfBusinessUS"
  }
};
