import React, { PureComponent } from "react";
import { AppBar, Toolbar, Typography, Box } from "@material-ui/core";
import StepWizard from "react-step-wizard";

import { AuthContext } from "./components/Authentication";
import FormProgress from "./components/FormProgress";

import SectionIntro from "./containers/SectionIntro";
import Welcome from "./containers/Welcome";
import Qualification from "./containers/Qualification";
import Disqualification from "./containers/Disqualification";
import BasicInfo from "./containers/BasicInfo";
import VerifyIdentity from "./containers/VerifyIdentity";
import BusinessInfo from "./containers/BusinessInfo";
import BusinessInfo2 from "./containers/BusinessInfo2";
import VerifyPayroll from "./containers/VerifyPayroll";
import PayrollInputs from "./containers/PayrollInputs";
import Owners from "./containers/Owners";
import VerifyLoan from "./containers/VerifyLoan";
import Complete from "./containers/Complete";
import Result from "./containers/Result";

import { ReactComponent as Logo } from "./assets/Sortis-SBA-logo-black.svg";

import "./App.css";
import {
  testFunc,
  // testEmail,
  checkIdentity,
  getPayrollData,
  submitFormBasic,
  checkShutDown
} from "./util/functions";

import { packageFormData } from "./util/helpers";

class App extends PureComponent {
  state = {
    shutoff: false,
    uid: "",
    isLoadingIdentity: false,
    isLoadingPayroll: false,
    isLoadingSubmit: false,
    hasSuccessfullySubmitted: false,
    submitError: null,
    identityError: null,
    payrollError: null,
    // data
    // qualifiers
    verifyTimeInOperation: false,
    verifyEconomicUncertainty: false,
    verifyUseOfFunds: false,
    verifyNoAdditionalPPPLoan: false,
    verifyQualifiedBusiness: false,
    verifyPrinciplePlaceOfBusinessUS: false,
    passedDisqual: false,
    // banking info
    firstName: "",
    lastName: "",
    email: "",
    zipcode: "",
    tel: "",
    // biz data
    entityType: "",
    entityName: "",
    dba: "",
    tin: "",
    NAICSCode: "",
    isFranchise: false,
    businessAddressLine1: "",
    businessAddressLine2: "",
    businessAddressCity: "",
    businessAddressState: "",
    businessAddressZipcode: "",
    businessAddressZipcodeFourDigit: "",
    businessPrimaryTel: "",
    businessFoundedDate: "",
    // payroll
    isSeasonal: false,
    isNew: false,
    owners: [],
    loanPurpose: {},
    //
    payrollAmount: 0,
    prevLoanAmount: 0,
    employeeCount: 0,
    PPPLoanAmount: 0,
    //
    displayPlaidIdentity: true,
    displayPlaidPayroll: false,
    identityPass: null,
    payrollLinked: false,
    usingPlaidPayroll: true,
    showIdentityModal: false,
    showPayrollModal: false,
    customerTracking: "",
    loanNumber: "",
    //
    signedName: "",
    signedDate: "",
    agreeFinalTerms: false
  };

  async componentDidMount() {
    window.addEventListener("hashchange", this.scrollToTop);
    const data = await checkShutDown();
    if (data.shutoff) {
      this.setState({ shutoff: true });
    }
  }

  async componentDidUpdate() {
    const { isAuthenticated } = this.context;
    if (isAuthenticated) {
      if (process.env.NODE_ENV === "development") {
        // run test function to check connection to firebase cloud functions
        await testFunc();
        // await testEmail();
      }
    }
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  togglePlaidButton = () => {
    this.setState(prevState => ({
      displayPlaidPayroll: true
    }));
  };

  setQual1 = ({
    verifyTimeInOperation,
    verifyEconomicUncertainty,
    verifyUseOfFunds,
    verifyNoAdditionalPPPLoan,
    verifyQualifiedBusiness,
    verifyPrinciplePlaceOfBusinessUS
  }) => {
    this.setState({
      verifyTimeInOperation,
      verifyEconomicUncertainty,
      verifyUseOfFunds,
      verifyNoAdditionalPPPLoan,
      verifyQualifiedBusiness,
      verifyPrinciplePlaceOfBusinessUS
    });
  };

  setDisqual = ({ passedDisqual }) => {
    this.setState({ passedDisqual });
  };

  setBasicInfo = ({ firstName, lastName, email, zipcode, tel }) => {
    this.setState({ firstName, lastName, email, zipcode, tel });
  };

  setBusInfo1 = ({
    entityType,
    entityName,
    dba,
    tin,
    isFranchise = false,
    businessFoundedDate,
    NAICSCode,
    isSeasonal = false,
    isNew = false
  }) => {
    this.setState({
      entityType,
      entityName,
      dba,
      tin,
      isFranchise,
      NAICSCode,
      isSeasonal,
      isNew,
      businessFoundedDate: businessFoundedDate
    });
  };

  setBusInfo2 = ({
    businessAddressLine1,
    businessAddressLine2 = "",
    businessAddressCity,
    businessAddressState,
    businessAddressZipcode,
    businessAddressZipcodeFourDigit,
    businessPrimaryTel,
    loanPurpose
  }) => {
    this.setState({
      businessAddressLine1,
      businessAddressLine2,
      businessAddressCity,
      businessAddressState,
      businessAddressZipcode,
      businessAddressZipcodeFourDigit,
      businessPrimaryTel,
      loanPurpose
    });
  };

  setPayroll2 = ({
    payrollAmount,
    prevLoanAmount,
    employeeCount,
    PPPLoanAmount
  }) => {
    this.setState({
      PPPLoanAmount,
      payrollAmount,
      prevLoanAmount,
      employeeCount
    });
  };

  setPayroll1 = ({ usingPlaidPayroll }) =>
    this.setState({
      usingPlaidPayroll
    });

  setOwners = arr => {
    this.setState({
      owners: arr
    });
  };

  setSignature = ({ agreeFinalTerms, signedName, signedDate }) => {
    this.setState({
      agreeFinalTerms,
      signedName,
      signedDate
    });
  };

  identityLinkCallback = async payload => {
    this.setState({
      isLoadingIdentity: true,
      showIdentityModal: true,
      identityError: null
    });
    payload.zipcode = this.state.zipcode;
    payload.email = this.state.email;
    payload.firstName = this.state.firstName;
    payload.lastName = this.state.lastName;
    payload.tel = this.state.tel && this.state.tel.replace(/\D/g, "");
    payload.uid = this.context.uid || "";
    try {
      window.intercomSettings.name = `${this.state.firstName} ${this.state.lastName}`;
      const pass = await checkIdentity(payload);
      if (pass.message === "pass") {
      } else {
        throw Error("failed");
      }
      this.setState({
        identityPass: true,
        isLoadingIdentity: false
      });
    } catch (error) {
      console.error("error fail", error.message);
      this.setState({
        isLoadingIdentity: false,
        identityPass: false,
        identityError: error
      });
    }
  };

  payrollLinkCallback = async payload => {
    // save payroll data in backend
    try {
      this.setState({
        isLoadingPayroll: true,
        payrollError: null
      });
      payload.isSeasonal = this.state.isSeasonal;
      payload.isNew = this.state.isNew;
      payload.uid = this.context.uid;
      const data = await getPayrollData(payload);
      const { ppp_average_monthly_payroll, employee_count = 0 } = data || {};
      this.setState({
        payrollLinked: true,
        isLoadingPayroll: false,
        payrollAmount:
          ppp_average_monthly_payroll &&
          Math.round(ppp_average_monthly_payroll),
        employeeCount: employee_count
      });
    } catch (error) {
      this.setState({
        isLoadingPayroll: false,
        payrollError: error.message
      });
      console.error("error in payroll callback", error);
    }
  };

  submitForm = async () => {
    try {
      this.setState({ isLoadingSubmit: true, submitError: null });
      const payload = {
        uid: this.context.uid
      };
      const applicantData = packageFormData(this.state);
      payload.applicantData = applicantData;
      const data = await submitFormBasic(payload);
      console.log("data after submit", data);
      const message = data.message;
      if (message === "success") {
        this.setState({
          isLoadingSubmit: false,
          hasSuccessfullySubmitted: true,
          customerTracking: data.trackingNumber,
          loanNumber: data.loanNumber
        });
      } else {
        this.setState({
          isLoadingSubmit: false,
          hasSuccessfullySubmitted: false,
          customerTracking: data.trackingNumber,
          submitError: "Failed to electronically submit"
        });
      }
    } catch (error) {
      this.setState({
        isLoadingSubmit: false,
        submitError: error.message
      });
      console.error("error sub form", error);
    }
  };

  closeIdentityModal = () => {
    this.setState({
      showIdentityModal: false
    });
  };

  render() {
    return (
      <div className="App">
        <AppBar position="static" color="inherit">
          <Toolbar className="Toolbar">
            <Box>
              <div className="LogoContainer">
                <Logo />
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <form noValidate autoComplete="off">
          <StepWizard isHashEnabled nav={<FormProgress />}>
            <Welcome
              next="#step-1"
              hashkey="welcome"
              data-component="Welcome"
              shutoff={this.state.shutoff}
            />
            <SectionIntro hashKey={"step-1"} next="#qualification-1">
              <Box>
                <Typography variant="h4">
                  First, we need to ask some pre-qualifying questions.
                </Typography>
                <Box mt={30}>
                  <Typography variant="body1">
                    Your answers make sure you are going to be eligible for this
                    loan.
                  </Typography>
                </Box>
              </Box>
            </SectionIntro>
            <Qualification
              hashKey={"qualification-1"}
              questionMin={1}
              questionMax={6}
              setInputs={this.setQual1}
              next="#qualification-2"
              back="#step-1"
            />
            <Disqualification
              hashKey={"qualification-2"}
              setInputs={this.setDisqual}
              next="#identity-inputs"
              back="#qualification-1"
            />
            {/* <SectionIntro
              hashKey={"step-2"}
              back="#qualification-2"
              next="#identity-inputs"
            >
              <Box>
                <Typography variant="h4">
                  Next, we need to verify your banking identity
                </Typography>
                <Box mt={30}>
                  <Typography variant="body1">
                    We use Plaid to verify your identity. First we'll ask for
                    some basic info that your bank would have on file for you.
                    Then, we'll ask you to login to your bank through Plaid, to
                    verify those details match what your bank account info.
                  </Typography>
                </Box>
              </Box>
            </SectionIntro> */}
            <BasicInfo
              hashKey={"identity-inputs"}
              next="#identity-banking"
              back="#qualification-2"
              setInputs={this.setBasicInfo}
            />
            <VerifyIdentity
              hashKey={"identity-banking"}
              next="#step-2"
              back="#identity-inputs"
              callback={this.identityLinkCallback}
              displayPlaid={this.state.displayPlaidIdentity}
              isLoading={this.state.isLoadingIdentity}
              showModal={this.state.showIdentityModal}
              modalCloseHandler={this.closeIdentityModal}
              hasPassed={this.state.identityPass}
              addPlaidLink={this.togglePlaidButton}
              payrollLinked={this.state.payrollLinked}
              identityError={this.state.identityError}
            />
            <SectionIntro
              hashKey={"step-2"}
              next="#business-information"
              back="#identity-banking"
            >
              <Box>
                <Typography variant="h4">
                  Next, we're going to collect some info about your business.
                </Typography>
              </Box>
            </SectionIntro>
            <BusinessInfo
              hashKey={"business-information"}
              next="#business-information-2"
              back="#step-2"
              setInputs={this.setBusInfo1}
            />
            <BusinessInfo2
              hashKey={"business-information-2"}
              next="#step-3"
              back="#business-information"
              setInputs={this.setBusInfo2}
            />
            <SectionIntro
              hashKey={"step-3"}
              next="#payroll-link"
              back="#business-information-2"
            >
              <Box>
                <Typography variant="h4">
                  Next, using your payroll figures, we're going to determine
                  your loan amount.
                </Typography>
                <Box mt={30}>
                  <Typography variant="body1">
                    First we're going to use Plaid again to find a suggested
                    payroll amount, then you can confirm the payroll and loan
                    amount.
                  </Typography>
                </Box>
              </Box>
            </SectionIntro>
            <VerifyPayroll
              hashKey={"payroll-link"}
              next="#payroll-figures"
              back="#step-3"
              displayPlaid={this.state.displayPlaidPayroll}
              isLoading={this.state.isLoadingPayroll}
              callback={this.payrollLinkCallback}
              setInputs={this.setPayroll1}
              payrollLinked={this.state.payrollLinked}
              usingPlaidPayroll={this.state.usingPlaidPayroll}
              payrollError={this.payrollError}
            />
            <PayrollInputs
              hashKey={"payroll-figures"}
              next="#step-4"
              back="#payroll-link"
              setInputs={this.setPayroll2}
              payrollLinked={this.state.payrollLinked}
              payrollAmount={this.state.payrollAmount}
              employeeCount={this.state.employeeCount}
              prevLoanAmount={this.state.prevLoanAmount}
            />
            <SectionIntro
              hashKey={"step-4"}
              next="#owners"
              back="#payroll-figures"
            >
              <Box>
                <Typography variant="h4">We’re almost done!</Typography>
              </Box>
              <Box mt={30}>
                <Typography variant="body1">
                  Now we’ll collect information about your Applicants primary
                  stakeholders/ owners.
                </Typography>
              </Box>
            </SectionIntro>
            <Owners
              hashKey={"owners"}
              back="#step-4"
              next="#verify-loan"
              setInputs={this.setOwners}
            />
            <VerifyLoan
              hashKey={"verify-loan"}
              back="#owners"
              next="#complete"
              {...this.state}
            />
            <Complete
              hashKey={"complete"}
              back={"#verify-loan"}
              next={"#result"}
              submitForm={this.submitForm}
              setInputs={this.setSignature}
              isLoadingSubmit={this.state.isLoadingSubmit}
              hasSuccessfullySubmitted={this.state.hasSuccessfullySubmitted}
              submitError={this.state.submitError}
            />
            <Result hashKey={"result"} back="#complete" {...this.state} />
          </StepWizard>
        </form>
      </div>
    );
  }
}

App.contextType = AuthContext;

export default App;
