import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box
} from "@material-ui/core";

import { FormNavButton, FormNavButtons } from "../../components/FormNavButtons";
import Layout from "../../components/Layout";
import questions from "./questions";
import copy from "./copy.json";

import { cloneDeep } from "lodash";

const Qualification = ({
  questionMin,
  questionMax,
  next,
  previousStep,
  nextStep,
  back,
  setInputs: setFormInputs
}) => {
  const initArr = Object.keys(questions)
    .slice(questionMin - 1, questionMax)
    .map(key => {
      return {
        [questions[key].name]: null
      };
    });
  const [selected, setSelected] = useState(initArr);

  const questionSet = Object.keys(questions)
    .slice(questionMin - 1, questionMax)
    .map(key => {
      return {
        name: questions[key].name,
        num: key
      };
    });

  const handleCheckboxChange = (e, i) => {
    e.persist();
    const newArr = cloneDeep(selected);
    newArr[i][e.target.name] = e.target.checked;
    setSelected(newArr);
  };

  const isProgressDisabled = !selected.every(sel => {
    return !!Object.values(sel)[0];
  });

  const goForward = () => {
    const newObj = selected.reduce((obj, item) => {
      const key = Object.keys(item)[0];
      const value = Object.values(item)[0];
      obj[key] = value;
      return obj;
    }, {});
    setFormInputs(newObj);
    nextStep();
  };

  return (
    <Layout centered>
      <Container maxWidth="sm">
        <Box>
          <Box mb={20}>
            <Typography variant="h4">{copy.heading}</Typography>
          </Box>
          <Box>
            <Typography variant="subheading1">{copy.subheading}</Typography>
          </Box>
        </Box>
        <Box>
          {questionSet.map((question, i) => (
            <Box mt={50}>
              <Box pb={15}>
                <Typography variant="h6">
                  {questions[question.num].heading}
                </Typography>
              </Box>

              <Box>
                <FormControl>
                  <FormControlLabel
                    style={{ display: "flex", alignItem: "flex-start" }}
                    control={
                      <Checkbox
                        checked={selected[i][questions[question.num].name]}
                        onChange={e => handleCheckboxChange(e, i)}
                        name={questions[question.num].name}
                        color="secondary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {questions[question.num].copy}
                      </Typography>
                    }
                  />
                </FormControl>
              </Box>
            </Box>
          ))}
        </Box>

        <FormNavButtons>
          <FormNavButton
            previousFunc={previousStep}
            back={back}
          ></FormNavButton>
          <FormNavButton
            disabled={isProgressDisabled}
            next={next}
            nextFunc={goForward}
          ></FormNavButton>
        </FormNavButtons>
      </Container>
    </Layout>
  );
};

export default Qualification;
